import React from 'react'
import { inspect } from 'util'
import { config } from '../config'
import { useEth } from '../Providers/Eth/EthProvider'
import { useWvs } from '../Providers/Wvs/WvsProvider'
import { useLogger } from './useLogger'

export type SwapStatus =
  | 'allow_deposit'
  | 'wait_deposit'
  | 'wait_oracle'
  | 'completed'
  | 'failed'
  | 'ready'

export interface SwapContext {
  increaseAllowanceTxHash?: string
  depositTxHash?: string
  gatewayTxId?: string
}

export interface SwapState {
  status: SwapStatus
  context: SwapContext
  error: string | null
  init: (input: string) => Promise<void>
  clear: () => Promise<void>
  canClear: () => boolean
}

export const calculateValue = (input: string) => {
  return BigInt(Math.floor(Number(input) * 10 ** 8))
}

export const stringifyError = (err: any) => {
  if (typeof err === 'string') return err

  if (typeof err?.message === 'string') return err.message

  try {
    return JSON.stringify(err, null, 2)
  } catch {
    return inspect(err)
  }
}

export const useSwap = (): SwapState => {
  const [status, setStatus] = React.useState<SwapStatus>('ready')
  const [context, setContext] = React.useState<SwapContext>({})
  const [error, setError] = React.useState<string | null>(null)

  const { service: ethService } = useEth()
  const { service: wvsService, forceUserBalanceUpdate } = useWvs()
  const logger = useLogger()

  const init = async (input: string) => {
    if (status !== 'ready') return

    logger.info('Starting to process transaction')

    await process(input)
  }

  const process = async (input: string) => {
    const value = calculateValue(input)

    logger.info(`Input = ${input}, Output = ${value.toString()}`)

    try {
      const id = await handleDeposit(value)
      await handleReturnTx(id)
    } catch (e: any) {
      console.error(e)
      logger.error('Swap failed: ' + inspect(e))
      setStatus('failed')
      handleError(e)
    }
  }

  const handleError = (e: any) => {
    if (e?.code === 4001) {
      setError('Transaction rejected by user')
      return
    }

    setError(stringifyError(e))
  }

  const handleDeposit = async (value: bigint) => {
    setStatus('allow_deposit')

    logger.info('Waiting for tx confirmation')

    const wvsAddress = wvsService.publicState?.account?.address
    logger.info(`Wvs address is ${wvsAddress}`)

    if (!wvsAddress) throw new Error('no wvs address')

    const tx = await ethService.createContractCallTx(value, wvsAddress)
    logger.info('tx', tx)

    const hash = await ethService.sendTx(tx)
    logger.info('Waiting for user approval')

    setContext((ctx) => ({ ...ctx, depositTxHash: hash }))
    setStatus('wait_deposit')

    logger.info('Waiting for tx confirmation')
    const receipt = await ethService.waitForTx(hash)

    logger.info(`Tx hash is ${receipt.transactionHash}`)
    return receipt.transactionHash
  }

  const handleReturnTx = async (id: string) => {
    setContext((ctx) => ({ ...ctx, gatewayTxId: id }))
    setStatus('wait_oracle')

    logger.info('Waiting for return tx')
    const success = await wvsService.waitForReturnTx(id, 4000, config.wvs.returnTxTimeout)

    if (success) {
      logger.info('Swap completed')
      setStatus('completed')
    } else {
      setError(
        'Return transaction timeout reached. Your transaction might still be confirmed later'
      )
      logger.error('Swap timeout reached: ' + id)
      setStatus('failed')
    }

    forceUserBalanceUpdate()
  }

  const canClear = () => status === 'failed' || status === 'completed'

  const clear = async () => {
    if (canClear()) {
      setStatus('ready')
      setContext({})
      setError(null)
    }
  }

  return {
    status,
    context,
    error,
    init,
    clear,
    canClear
  }
}
