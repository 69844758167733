import React from 'react'
import { MetaMaskProvider, useMetaMask } from 'metamask-react'
import { IMetaMaskContext } from 'metamask-react/lib/metamask-context'
import { EthService } from './EthService'
import { config } from '../../config'
import { OracleStatus, useOracleStatus } from '../../Hooks/useOracleStatus'

export interface EthConfig {
  rpcUrl: string
  tokenAddress: string
  oracleId: string
  chainId: number
  oracleUrl: string
}

export interface EthProviderProps {
  config: EthConfig
}

export interface EthState {
  metamask: IMetaMaskContext
  config: EthConfig
  service: EthService
  userTokenBalance: bigint
  oracleStatus: OracleStatus
}

export const EthContext = React.createContext<EthState>(null as any)

export const EthProviderState: React.FC<EthProviderProps> = (props) => {
  const [userTokenBalance, setUserTokenBalance] = React.useState(0n)
  const metamask = useMetaMask()
  const service = new EthService(props.config)

  const { status: oracleStatus } = useOracleStatus({
    url: props.config.oracleUrl,
    interval: 5000,
    timeout: 7200000
  })

  React.useEffect(() => {
    if (
      metamask.status !== 'connected' ||
      !metamask.account ||
      metamask.chainId?.replace('0x', '') !== config.eth.chainId?.toString(16)
    ) {
      return
    }

    service.userTokenBalance().then(setUserTokenBalance)
    // eslint-disable-next-line
  }, [metamask])

  const state: EthState = {
    metamask,
    config: props.config,
    service,
    userTokenBalance,
    oracleStatus
  }

  return <EthContext.Provider value={state}>{props.children}</EthContext.Provider>
}

export const EthProvider: React.FC<EthProviderProps> = (props) => {
  return (
    <MetaMaskProvider>
      <EthProviderState {...props}>{props.children}</EthProviderState>
    </MetaMaskProvider>
  )
}

export const useEth = () => React.useContext(EthContext)
