import React from 'react'
import {
  Box,
  Alert,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress,
  Typography
} from '@mui/material'
import Space from '../Components/Space'
import { SwapState, SwapStatus } from '../Hooks/useSwap'
import { useInput } from '../Providers/Input/InputProvider'

export interface StepPayload {
  index: number
  slug: SwapStatus
  label: string
  loading: boolean
  render: React.FC<SwapState>
}

const steps: StepPayload[] = [
  {
    index: 0,
    slug: 'allow_deposit',
    label: 'Approve transaction',
    loading: true,
    render: () => null
  },
  {
    index: 1,
    slug: 'wait_deposit',
    label: 'Wait for transaction confirmation',
    loading: true,
    render: (props) => (
      <span style={{ wordBreak: 'break-word' }}>{props.context.depositTxHash}</span>
    )
  },
  {
    index: 2,
    slug: 'wait_oracle',
    label: 'Wait for return transaction',
    loading: true,
    render: (props) => (
      <span style={{ wordBreak: 'break-word' }}>{props.context.gatewayTxId}</span>
    )
  },
  {
    index: 3,
    slug: 'completed',
    label: 'Swap completed',
    loading: false,
    render: () => null
  }
]

export interface TransactionStepsProps {
  swap: SwapState
}

const TransactionSteps: React.FC<TransactionStepsProps> = (props) => {
  const [activeStep, setActiveStep] = React.useState(0)
  const { input } = useInput()

  React.useEffect(() => {
    const step = steps.find((step) => props.swap.status === step.slug)
    if (!step) return

    setActiveStep(step?.index ?? 0)
  }, [props.swap.status])

  return (
    <Box>
      <Typography variant="body2">Swapping {input} SKey</Typography>
      <Space />
      {props.swap.error && (
        <Alert variant="filled" severity="error" sx={{ marginBottom: '20px' }}>
          Swap failed!
        </Alert>
      )}
      {props.swap.status === 'completed' && (
        <Alert variant="filled" severity="success" sx={{ marginBottom: '20px' }}>
          Swap completed!
        </Alert>
      )}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => {
          const Component = step.render

          return (
            <Step key={step.index}>
              <StepLabel
                error={props.swap.status === 'failed' && activeStep === step.index}
              >
                {step.label}
              </StepLabel>
              <StepContent sx={{ paddingTop: '15px', paddingLeft: '25px' }}>
                {step.loading && props.swap.status !== 'failed' && (
                  <CircularProgress size={25} sx={{ mb: '15px' }} />
                )}
                <Component {...props.swap} />
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

export default TransactionSteps
